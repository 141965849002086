import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetTimePeriodListQueryVariables = Types.Exact<{
  _ilike?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetTimePeriodListQuery = { __typename?: 'query_root', time_period: Array<{ __typename?: 'time_period', datePeriod: string, timePeriod: string }> };

export type GetPerformanceReviewTableDataQueryVariables = Types.Exact<{
  _eq?: Types.InputMaybe<Types.Scalars['uuid']>;
  _eq1?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetPerformanceReviewTableDataQuery = { __typename?: 'query_root', performance_review_settings: Array<{ __typename?: 'performance_review_settings', id: any, frequency: string, year: string, instruction: string, organization_Id: any, startDate?: string | null, endDate?: string | null, name?: string | null, org_goal_share?: string | null, copy_previews_performance_goals?: boolean | null, self_review?: string | null, manager_review?: string | null, peer_review?: string | null, view_option?: boolean | null, performance_review_setting_With_Goals: Array<{ __typename?: 'performance_review_setting_With_Goals', action: string, dueDate: any, id: any, startDate: any, timePeriod: string, status: string, startingDate?: string | null, endingDate?: string | null, title: string, year: string, preformance_Id: any, viewScoreOption?: string | null, performance_review_setting_GoalsLists_aggregate: { __typename?: 'performance_review_setting_GoalsList_aggregate', aggregate?: { __typename?: 'performance_review_setting_GoalsList_aggregate_fields', count: number } | null } }>, performanceReviewStatuses_aggregate: { __typename?: 'performanceReviewStatus_aggregate', aggregate?: { __typename?: 'performanceReviewStatus_aggregate_fields', count: number } | null }, organization: { __typename?: 'organization', organization_employees_aggregate: { __typename?: 'organization_employee_aggregate', aggregate?: { __typename?: 'organization_employee_aggregate_fields', count: number } | null } }, performance_rating_scales: Array<{ __typename?: 'performance_rating_scale', id: any, name?: string | null, rating?: number | null }> }> };

export type CreatePerformanceReviewMutationVariables = Types.Exact<{
  object: Types.PerformanceReviewInput;
}>;


export type CreatePerformanceReviewMutation = { __typename?: 'mutation_root', createPerformanceReview?: { __typename?: 'PerformanceReviewOutput', id: any } | null };

export type UpdatePerformanceReviewMutationVariables = Types.Exact<{
  object: Types.UpdatePerofrmanceReviewInput;
}>;


export type UpdatePerformanceReviewMutation = { __typename?: 'mutation_root', updatePerformanceReview?: { __typename?: 'UpdatePerofrmanceReviewOutput', id: any } | null };

export type FinancialYearValueQueryVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
  todaysDate: Types.Scalars['String'];
}>;


export type FinancialYearValueQuery = { __typename?: 'query_root', performance_review_settings: Array<{ __typename?: 'performance_review_settings', startDate?: string | null, endDate?: string | null, year: string }> };

export type GetGoalNoPerRolesMutationVariables = Types.Exact<{
  object: Types.GoalCountPerRolesInput;
}>;


export type GetGoalNoPerRolesMutation = { __typename?: 'mutation_root', getGoalNoPerRoles?: { __typename?: 'GoalCountPerRolesOutput', response: Array<{ __typename?: 'GoalDetailPerRoles', roleTitle?: string | null, count?: number | null, managerId?: any | null, roleId?: any | null } | null> } | null };

export type PerformanceReviewNotificationForManagerMutationVariables = Types.Exact<{
  object: Types.PerformanceReviewNotifyInput;
}>;


export type PerformanceReviewNotificationForManagerMutation = { __typename?: 'mutation_root', performanceReviewNotification?: { __typename?: 'PerformanceReviewNotifyOutput', response: string } | null };

export type Get_ListingYear_Org_AdminQueryVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
}>;


export type Get_ListingYear_Org_AdminQuery = { __typename?: 'query_root', performance_review_settings: Array<{ __typename?: 'performance_review_settings', year: string }> };

export type Get_TimePeriod_ListMutationVariables = Types.Exact<{
  object: Types.TimePeriodListInput;
}>;


export type Get_TimePeriod_ListMutation = { __typename?: 'mutation_root', getTimePeriodList?: { __typename?: 'TimePeriodListOutput', response: Array<{ __typename?: 'timePeriodRespList', timePeriod?: string | null, year?: string | null } | null> } | null };

export type CheckingPerformanceReviewStatusQueryVariables = Types.Exact<{
  _eq1: Types.Scalars['uuid'];
  _eq: Types.Scalars['uuid'];
}>;


export type CheckingPerformanceReviewStatusQuery = { __typename?: 'query_root', performanceReviewStatus_aggregate: { __typename?: 'performanceReviewStatus_aggregate', aggregate?: { __typename?: 'performanceReviewStatus_aggregate_fields', count: number } | null } };

export type CheckGoalCountMutationVariables = Types.Exact<{
  object: Types.GoalCountPerTimeperiodInput;
}>;


export type CheckGoalCountMutation = { __typename?: 'mutation_root', checkGoalCount?: { __typename?: 'goalCountPerTimeperiodOutput', response: string } | null };

export type LoadPerformanceReviewMutationVariables = Types.Exact<{
  object: Types.LoadPerformanceReviewInput;
}>;


export type LoadPerformanceReviewMutation = { __typename?: 'mutation_root', loadPerformanceReview?: { __typename?: 'LoadPerformanceReviewOutput', response?: Array<{ __typename?: 'List_Of_Performance_Review', id?: any | null, name?: string | null, duration?: string | null, year?: string | null, status?: string | null, period?: Array<{ __typename?: 'List_Of_TimePeriod', id?: any | null, title?: any | null, timePeriod?: string | null, startDate?: string | null, dueDate?: string | null, startingDate?: string | null, endingDate?: string | null, status?: string | null, action?: string | null, goalDetails?: number | null } | null> | null } | null> | null } | null };

export type PreviousYearTimePeriodQueryVariables = Types.Exact<{
  org_id: Types.Scalars['uuid'];
  year: Types.Scalars['String'];
}>;


export type PreviousYearTimePeriodQuery = { __typename?: 'query_root', performance_review_settings: Array<{ __typename?: 'performance_review_settings', performance_review_setting_With_Goals: Array<{ __typename?: 'performance_review_setting_With_Goals', timePeriod: string }> }> };



export function useGetTimePeriodListQuery(options?: Omit<Urql.UseQueryArgs<Operations.GetTimePeriodListQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.GetTimePeriodListQuery, Operations.GetTimePeriodListQueryVariables>({ query: Operations.GetTimePeriodListDocument, ...options });
};

export function useGetPerformanceReviewTableDataQuery(options?: Omit<Urql.UseQueryArgs<Operations.GetPerformanceReviewTableDataQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.GetPerformanceReviewTableDataQuery, Operations.GetPerformanceReviewTableDataQueryVariables>({ query: Operations.GetPerformanceReviewTableDataDocument, ...options });
};

export function useCreatePerformanceReviewMutation() {
  return Urql.useMutation<Operations.CreatePerformanceReviewMutation, Operations.CreatePerformanceReviewMutationVariables>(Operations.CreatePerformanceReviewDocument);
};

export function useUpdatePerformanceReviewMutation() {
  return Urql.useMutation<Operations.UpdatePerformanceReviewMutation, Operations.UpdatePerformanceReviewMutationVariables>(Operations.UpdatePerformanceReviewDocument);
};

export function useFinancialYearValueQuery(options: Omit<Urql.UseQueryArgs<Operations.FinancialYearValueQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.FinancialYearValueQuery, Operations.FinancialYearValueQueryVariables>({ query: Operations.FinancialYearValueDocument, ...options });
};

export function useGetGoalNoPerRolesMutation() {
  return Urql.useMutation<Operations.GetGoalNoPerRolesMutation, Operations.GetGoalNoPerRolesMutationVariables>(Operations.GetGoalNoPerRolesDocument);
};

export function usePerformanceReviewNotificationForManagerMutation() {
  return Urql.useMutation<Operations.PerformanceReviewNotificationForManagerMutation, Operations.PerformanceReviewNotificationForManagerMutationVariables>(Operations.PerformanceReviewNotificationForManagerDocument);
};

export function useGet_ListingYear_Org_AdminQuery(options: Omit<Urql.UseQueryArgs<Operations.Get_ListingYear_Org_AdminQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.Get_ListingYear_Org_AdminQuery, Operations.Get_ListingYear_Org_AdminQueryVariables>({ query: Operations.Get_ListingYear_Org_AdminDocument, ...options });
};

export function useGet_TimePeriod_ListMutation() {
  return Urql.useMutation<Operations.Get_TimePeriod_ListMutation, Operations.Get_TimePeriod_ListMutationVariables>(Operations.Get_TimePeriod_ListDocument);
};

export function useCheckingPerformanceReviewStatusQuery(options: Omit<Urql.UseQueryArgs<Operations.CheckingPerformanceReviewStatusQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.CheckingPerformanceReviewStatusQuery, Operations.CheckingPerformanceReviewStatusQueryVariables>({ query: Operations.CheckingPerformanceReviewStatusDocument, ...options });
};

export function useCheckGoalCountMutation() {
  return Urql.useMutation<Operations.CheckGoalCountMutation, Operations.CheckGoalCountMutationVariables>(Operations.CheckGoalCountDocument);
};

export function useLoadPerformanceReviewMutation() {
  return Urql.useMutation<Operations.LoadPerformanceReviewMutation, Operations.LoadPerformanceReviewMutationVariables>(Operations.LoadPerformanceReviewDocument);
};

export function usePreviousYearTimePeriodQuery(options: Omit<Urql.UseQueryArgs<Operations.PreviousYearTimePeriodQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.PreviousYearTimePeriodQuery, Operations.PreviousYearTimePeriodQueryVariables>({ query: Operations.PreviousYearTimePeriodDocument, ...options });
};
import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrganizationAdminsByOrganizationIdQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid'];
}>;


export type GetOrganizationAdminsByOrganizationIdQuery = { __typename?: 'query_root', organization_admin: Array<{ __typename?: 'organization_admin', id: any, is_owner: boolean, userByUserId: { __typename?: 'users', id: any, display_name?: string | null, account?: { __typename?: 'auth_accounts', email?: any | null } | null } }>, organization_admin_aggregate: { __typename?: 'organization_admin_aggregate', aggregate?: { __typename?: 'organization_admin_aggregate_fields', count: number } | null } };

export type DeleteOrganizationAdminMutationVariables = Types.Exact<{
  object: Types.DeleteOrgAdminInput;
}>;


export type DeleteOrganizationAdminMutation = { __typename?: 'mutation_root', deleteOrgAdmin?: { __typename?: 'DeleteOrgAdminOutput', message?: string | null, ok?: boolean | null, status?: number | null } | null };

export type TransferOrganizationOwnershipMutationVariables = Types.Exact<{
  object: Types.TransferOwnershipInput;
}>;


export type TransferOrganizationOwnershipMutation = { __typename?: 'mutation_root', transferOwnership?: { __typename?: 'TransferOwnershipOutput', message?: string | null, ok?: boolean | null, status?: number | null } | null };

export type SaveOrganizationAdminMutationVariables = Types.Exact<{
  object: Types.SaveOrganizationAdminInput;
}>;


export type SaveOrganizationAdminMutation = { __typename?: 'mutation_root', saveOrganizationAdmin: { __typename?: 'SaveOrganizationAdminOutput', userId: any } };



export function useGetOrganizationAdminsByOrganizationIdQuery(options: Omit<Urql.UseQueryArgs<Operations.GetOrganizationAdminsByOrganizationIdQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.GetOrganizationAdminsByOrganizationIdQuery, Operations.GetOrganizationAdminsByOrganizationIdQueryVariables>({ query: Operations.GetOrganizationAdminsByOrganizationIdDocument, ...options });
};

export function useDeleteOrganizationAdminMutation() {
  return Urql.useMutation<Operations.DeleteOrganizationAdminMutation, Operations.DeleteOrganizationAdminMutationVariables>(Operations.DeleteOrganizationAdminDocument);
};

export function useTransferOrganizationOwnershipMutation() {
  return Urql.useMutation<Operations.TransferOrganizationOwnershipMutation, Operations.TransferOrganizationOwnershipMutationVariables>(Operations.TransferOrganizationOwnershipDocument);
};

export function useSaveOrganizationAdminMutation() {
  return Urql.useMutation<Operations.SaveOrganizationAdminMutation, Operations.SaveOrganizationAdminMutationVariables>(Operations.SaveOrganizationAdminDocument);
};
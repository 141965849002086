import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ActivateAccountMutationVariables = Types.Exact<{
  ticket: Types.Scalars['uuid'];
}>;


export type ActivateAccountMutation = { __typename?: 'mutation_root', activateAccount?: { __typename?: 'ActivateOutput', resetPassword?: string | null, refreshToken?: string | null } | null };

export type ResendActivateEmailMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type ResendActivateEmailMutation = { __typename?: 'mutation_root', resendActivateEmail?: { __typename?: 'ResendActivateEmailOutput', success?: boolean | null } | null };



export function useActivateAccountMutation() {
  return Urql.useMutation<Operations.ActivateAccountMutation, Operations.ActivateAccountMutationVariables>(Operations.ActivateAccountDocument);
};

export function useResendActivateEmailMutation() {
  return Urql.useMutation<Operations.ResendActivateEmailMutation, Operations.ResendActivateEmailMutationVariables>(Operations.ResendActivateEmailDocument);
};
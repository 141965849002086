import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrganizationByUserIdQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
}>;


export type GetOrganizationByUserIdQuery = { __typename?: 'query_root', organization_user: Array<{ __typename?: 'organization_user', organization?: { __typename?: 'organization', id: any, owned_by: any } | null }> };



export function useGetOrganizationByUserIdQuery(options: Omit<Urql.UseQueryArgs<Operations.GetOrganizationByUserIdQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.GetOrganizationByUserIdQuery, Operations.GetOrganizationByUserIdQueryVariables>({ query: Operations.GetOrganizationByUserIdDocument, ...options });
};
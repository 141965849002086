import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InsertUserProfileMutationVariables = Types.Exact<{
  object: Types.Profile_Insert_Input;
}>;


export type InsertUserProfileMutation = { __typename?: 'mutation_root', insert_profile_one?: { __typename?: 'profile', id: any } | null };

export type CreateOrganizationOwnerMutationVariables = Types.Exact<{
  profile: Types.ProfileInput;
  user: Types.UserInput;
}>;


export type CreateOrganizationOwnerMutation = { __typename?: 'mutation_root', createOrganizationOwner?: { __typename?: 'createOrganizationOwnerOutput', userId: any } | null };



export function useInsertUserProfileMutation() {
  return Urql.useMutation<Operations.InsertUserProfileMutation, Operations.InsertUserProfileMutationVariables>(Operations.InsertUserProfileDocument);
};

export function useCreateOrganizationOwnerMutation() {
  return Urql.useMutation<Operations.CreateOrganizationOwnerMutation, Operations.CreateOrganizationOwnerMutationVariables>(Operations.CreateOrganizationOwnerDocument);
};
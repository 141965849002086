import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddAssessmentTypeOrgAdminMutationVariables = Types.Exact<{
  objects?: Types.InputMaybe<Array<Types.Assessment_Type_Insert_Input> | Types.Assessment_Type_Insert_Input>;
}>;


export type AddAssessmentTypeOrgAdminMutation = { __typename?: 'mutation_root', insert_assessment_type?: { __typename?: 'assessment_type_mutation_response', returning: Array<{ __typename?: 'assessment_type', id: any }> } | null };

export type LoadAssessmentTypeOrgAdminQueryVariables = Types.Exact<{
  assessmentType?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  _eq: Types.Scalars['uuid'];
}>;


export type LoadAssessmentTypeOrgAdminQuery = { __typename?: 'query_root', assessment_type: Array<{ __typename?: 'assessment_type', name: string, meta_tags: string, id: any, user_id: any, status: boolean, organization_id: any, user: { __typename?: 'users', display_name?: string | null } }>, assessment_type_aggregate: { __typename?: 'assessment_type_aggregate', aggregate?: { __typename?: 'assessment_type_aggregate_fields', count: number } | null } };

export type UpdateAssessmentTypeMutationVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
  status?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type UpdateAssessmentTypeMutation = { __typename?: 'mutation_root', update_assessment_type?: { __typename?: 'assessment_type_mutation_response', returning: Array<{ __typename?: 'assessment_type', id: any }> } | null };

export type DeleteAssessmentTypeOrgAdminMutationVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
}>;


export type DeleteAssessmentTypeOrgAdminMutation = { __typename?: 'mutation_root', delete_assessment_type?: { __typename?: 'assessment_type_mutation_response', returning: Array<{ __typename?: 'assessment_type', id: any }> } | null };



export function useAddAssessmentTypeOrgAdminMutation() {
  return Urql.useMutation<Operations.AddAssessmentTypeOrgAdminMutation, Operations.AddAssessmentTypeOrgAdminMutationVariables>(Operations.AddAssessmentTypeOrgAdminDocument);
};

export function useLoadAssessmentTypeOrgAdminQuery(options: Omit<Urql.UseQueryArgs<Operations.LoadAssessmentTypeOrgAdminQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.LoadAssessmentTypeOrgAdminQuery, Operations.LoadAssessmentTypeOrgAdminQueryVariables>({ query: Operations.LoadAssessmentTypeOrgAdminDocument, ...options });
};

export function useUpdateAssessmentTypeMutation() {
  return Urql.useMutation<Operations.UpdateAssessmentTypeMutation, Operations.UpdateAssessmentTypeMutationVariables>(Operations.UpdateAssessmentTypeDocument);
};

export function useDeleteAssessmentTypeOrgAdminMutation() {
  return Urql.useMutation<Operations.DeleteAssessmentTypeOrgAdminMutation, Operations.DeleteAssessmentTypeOrgAdminMutationVariables>(Operations.DeleteAssessmentTypeOrgAdminDocument);
};
import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContactUsMutationVariables = Types.Exact<{
  object?: Types.InputMaybe<Types.ContactUsInput>;
}>;


export type ContactUsMutation = { __typename?: 'mutation_root', contactUs: { __typename?: 'ContactUsOutput', success: string } };



export function useContactUsMutation() {
  return Urql.useMutation<Operations.ContactUsMutation, Operations.ContactUsMutationVariables>(Operations.ContactUsDocument);
};
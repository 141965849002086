import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrganizationDetailsQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid'];
}>;


export type GetOrganizationDetailsQuery = { __typename?: 'query_root', organization: Array<{ __typename?: 'organization', anniversary_date?: any | null, organization_name: string, working_days: number, working_hours: number, holiday_file_id?: any | null, logo_file_id?: any | null, org_end_time: any, org_start_time: any, timezone_name: string, address?: { __typename?: 'address', id: any, address_line: string, country: string, pin_code: string, state: string } | null, organization_employees_aggregate: { __typename?: 'organization_employee_aggregate', aggregate?: { __typename?: 'organization_employee_aggregate_fields', count: number } | null } }> };

export type UpdateAddressDetailsMutationVariables = Types.Exact<{
  data: Types.Address_Set_Input;
  addressID: Types.Scalars['uuid'];
}>;


export type UpdateAddressDetailsMutation = { __typename?: 'mutation_root', update_address?: { __typename?: 'address_mutation_response', affected_rows: number } | null };

export type UpdateOrganizationDetailsMutationVariables = Types.Exact<{
  data: Types.Organization_Set_Input;
  organizationID: Types.Scalars['uuid'];
}>;


export type UpdateOrganizationDetailsMutation = { __typename?: 'mutation_root', update_organization?: { __typename?: 'organization_mutation_response', affected_rows: number } | null };



export function useGetOrganizationDetailsQuery(options: Omit<Urql.UseQueryArgs<Operations.GetOrganizationDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.GetOrganizationDetailsQuery, Operations.GetOrganizationDetailsQueryVariables>({ query: Operations.GetOrganizationDetailsDocument, ...options });
};

export function useUpdateAddressDetailsMutation() {
  return Urql.useMutation<Operations.UpdateAddressDetailsMutation, Operations.UpdateAddressDetailsMutationVariables>(Operations.UpdateAddressDetailsDocument);
};

export function useUpdateOrganizationDetailsMutation() {
  return Urql.useMutation<Operations.UpdateOrganizationDetailsMutation, Operations.UpdateOrganizationDetailsMutationVariables>(Operations.UpdateOrganizationDetailsDocument);
};
import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InsertOrganizationMutationVariables = Types.Exact<{
  object: Types.Organization_Insert_Input;
}>;


export type InsertOrganizationMutation = { __typename?: 'mutation_root', insert_organization_one?: { __typename?: 'organization', id: any } | null };

export type InsertOrganizationAdminMutationVariables = Types.Exact<{
  user_id: Types.Scalars['uuid'];
  object: Types.Organization_Admin_Insert_Input;
  default_role?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type InsertOrganizationAdminMutation = { __typename?: 'mutation_root', insert_organization_admin_one?: { __typename?: 'organization_admin', id: any } | null, update_auth_accounts?: { __typename?: 'auth_accounts_mutation_response', affected_rows: number } | null };

export type InsertAddressMutationVariables = Types.Exact<{
  object: Types.Address_Insert_Input;
}>;


export type InsertAddressMutation = { __typename?: 'mutation_root', insert_address_one?: { __typename?: 'address', id: any } | null };

export type CreateDepartmentsMutationVariables = Types.Exact<{
  object: Array<Types.Department_Insert_Input> | Types.Department_Insert_Input;
}>;


export type CreateDepartmentsMutation = { __typename?: 'mutation_root', insert_department?: { __typename?: 'department_mutation_response', affected_rows: number } | null };

export type InsertOrganizationLocationMutationVariables = Types.Exact<{
  object: Types.Organization_Locations_Insert_Input;
}>;


export type InsertOrganizationLocationMutation = { __typename?: 'mutation_root', insert_organization_locations_one?: { __typename?: 'organization_locations', id: any } | null };

export type CreateOrganizationMutationVariables = Types.Exact<{
  object: Types.CreateOrganizationInput;
}>;


export type CreateOrganizationMutation = { __typename?: 'mutation_root', createOrganization?: { __typename?: 'createOrganizationOutput', organizationId: any } | null };



export function useInsertOrganizationMutation() {
  return Urql.useMutation<Operations.InsertOrganizationMutation, Operations.InsertOrganizationMutationVariables>(Operations.InsertOrganizationDocument);
};

export function useInsertOrganizationAdminMutation() {
  return Urql.useMutation<Operations.InsertOrganizationAdminMutation, Operations.InsertOrganizationAdminMutationVariables>(Operations.InsertOrganizationAdminDocument);
};

export function useInsertAddressMutation() {
  return Urql.useMutation<Operations.InsertAddressMutation, Operations.InsertAddressMutationVariables>(Operations.InsertAddressDocument);
};

export function useCreateDepartmentsMutation() {
  return Urql.useMutation<Operations.CreateDepartmentsMutation, Operations.CreateDepartmentsMutationVariables>(Operations.CreateDepartmentsDocument);
};

export function useInsertOrganizationLocationMutation() {
  return Urql.useMutation<Operations.InsertOrganizationLocationMutation, Operations.InsertOrganizationLocationMutationVariables>(Operations.InsertOrganizationLocationDocument);
};

export function useCreateOrganizationMutation() {
  return Urql.useMutation<Operations.CreateOrganizationMutation, Operations.CreateOrganizationMutationVariables>(Operations.CreateOrganizationDocument);
};
import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddCompetencyMutationVariables = Types.Exact<{
  object: Types.AddCompetencyInput;
}>;


export type AddCompetencyMutation = { __typename?: 'mutation_root', addCompetency?: { __typename?: 'AddCompetencyOutput', id: any } | null };

export type ListOfCompetencyQueryVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
  _ilike?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ListOfCompetencyQuery = { __typename?: 'query_root', main_skills: Array<{ __typename?: 'main_skills', id: any, name: string, sub_skills: Array<{ __typename?: 'sub_skills', skill_Id?: any | null, list_Of_Skill?: { __typename?: 'list_Of_Skills', id: any, name: string, skills_details: Array<{ __typename?: 'skills_detail', rating?: number | null, description?: string | null }> } | null }> }>, main_skills_aggregate: { __typename?: 'main_skills_aggregate', aggregate?: { __typename?: 'main_skills_aggregate_fields', count: number } | null } };

export type UpdateCompetnecyNameMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  name: Types.Scalars['String'];
}>;


export type UpdateCompetnecyNameMutation = { __typename?: 'mutation_root', update_main_skills_by_pk?: { __typename?: 'main_skills', name: string } | null };

export type DeleteCompetencyMutationVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
}>;


export type DeleteCompetencyMutation = { __typename?: 'mutation_root', delete_sub_skills?: { __typename?: 'sub_skills_mutation_response', affected_rows: number } | null, delete_main_skills?: { __typename?: 'main_skills_mutation_response', returning: Array<{ __typename?: 'main_skills', id: any }> } | null };

export type EditCompetencyMutationVariables = Types.Exact<{
  object: Types.EditCompetencyInput;
}>;


export type EditCompetencyMutation = { __typename?: 'mutation_root', editCompetency?: { __typename?: 'EditCompetencyOutput', response: string } | null };

export type ViewCompetencyQueryVariables = Types.Exact<{
  _eq?: Types.InputMaybe<Types.Scalars['uuid']>;
  _eq1?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type ViewCompetencyQuery = { __typename?: 'query_root', main_skills: Array<{ __typename?: 'main_skills', id: any, name: string, org_Id: any, sub_skills: Array<{ __typename?: 'sub_skills', id: any, skill_Id?: any | null, list_Of_Skill?: { __typename?: 'list_Of_Skills', id: any, name: string, skills_details: Array<{ __typename?: 'skills_detail', id: any, rating?: number | null, description?: string | null }> } | null }> }> };



export function useAddCompetencyMutation() {
  return Urql.useMutation<Operations.AddCompetencyMutation, Operations.AddCompetencyMutationVariables>(Operations.AddCompetencyDocument);
};

export function useListOfCompetencyQuery(options: Omit<Urql.UseQueryArgs<Operations.ListOfCompetencyQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.ListOfCompetencyQuery, Operations.ListOfCompetencyQueryVariables>({ query: Operations.ListOfCompetencyDocument, ...options });
};

export function useUpdateCompetnecyNameMutation() {
  return Urql.useMutation<Operations.UpdateCompetnecyNameMutation, Operations.UpdateCompetnecyNameMutationVariables>(Operations.UpdateCompetnecyNameDocument);
};

export function useDeleteCompetencyMutation() {
  return Urql.useMutation<Operations.DeleteCompetencyMutation, Operations.DeleteCompetencyMutationVariables>(Operations.DeleteCompetencyDocument);
};

export function useEditCompetencyMutation() {
  return Urql.useMutation<Operations.EditCompetencyMutation, Operations.EditCompetencyMutationVariables>(Operations.EditCompetencyDocument);
};

export function useViewCompetencyQuery(options?: Omit<Urql.UseQueryArgs<Operations.ViewCompetencyQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.ViewCompetencyQuery, Operations.ViewCompetencyQueryVariables>({ query: Operations.ViewCompetencyDocument, ...options });
};
import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InsertOrganizationRoleMutationVariables = Types.Exact<{
  roleTitle: Types.Scalars['String'];
  systemRole: Types.Scalars['String'];
  orgId: Types.Scalars['uuid'];
}>;


export type InsertOrganizationRoleMutation = { __typename?: 'mutation_root', insert_organization_roles_one?: { __typename?: 'organization_roles', id: any } | null };

export type GetOrganizationRolesQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  roleTitle?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetOrganizationRolesQuery = { __typename?: 'query_root', organization_roles: Array<{ __typename?: 'organization_roles', id: any, role_title: string, system_role: string }>, totalFiltered: { __typename?: 'organization_roles_aggregate', aggregate?: { __typename?: 'organization_roles_aggregate_fields', count: number } | null } };

export type DeletRoleMutationVariables = Types.Exact<{
  role_id: Types.Scalars['String'];
}>;


export type DeletRoleMutation = { __typename?: 'mutation_root', deleteOrganizationRole?: { __typename?: 'DeleteOrgRoleOutput', message?: string | null, status?: number | null } | null };



export function useInsertOrganizationRoleMutation() {
  return Urql.useMutation<Operations.InsertOrganizationRoleMutation, Operations.InsertOrganizationRoleMutationVariables>(Operations.InsertOrganizationRoleDocument);
};

export function useGetOrganizationRolesQuery(options: Omit<Urql.UseQueryArgs<Operations.GetOrganizationRolesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.GetOrganizationRolesQuery, Operations.GetOrganizationRolesQueryVariables>({ query: Operations.GetOrganizationRolesDocument, ...options });
};

export function useDeletRoleMutation() {
  return Urql.useMutation<Operations.DeletRoleMutation, Operations.DeletRoleMutationVariables>(Operations.DeletRoleDocument);
};
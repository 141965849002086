import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrgAwardsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type GetOrgAwardsQuery = { __typename?: 'query_root', organization_awards: Array<{ __typename?: 'organization_awards', id: any, is_active: boolean, manager_permission: boolean, created_at: any, deleted_at?: any | null, award_types: Array<{ __typename?: 'awards', id: any, name: string, description?: string | null, cycle: Types.Enum_Award_Cycle_Type_Enum }> }> };

export type UpdateOrganizationAwardsByOrgIdMutationVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
  data: Types.Organization_Awards_Set_Input;
}>;


export type UpdateOrganizationAwardsByOrgIdMutation = { __typename?: 'mutation_root', update_organization_awards?: { __typename?: 'organization_awards_mutation_response', returning: Array<{ __typename?: 'organization_awards', id: any }> } | null };

export type InsertOrganizationAwardMutationVariables = Types.Exact<{
  object: Types.Organization_Awards_Insert_Input;
}>;


export type InsertOrganizationAwardMutation = { __typename?: 'mutation_root', insert_organization_awards_one?: { __typename?: 'organization_awards', organization_id: any } | null };

export type CreateNewAwardTypeMutationVariables = Types.Exact<{
  object: Types.CreateAwardTypeInput;
}>;


export type CreateNewAwardTypeMutation = { __typename?: 'mutation_root', createAwardType?: { __typename?: 'createAwardTypeOutput', name?: string | null } | null };

export type GetAllAwardsByOrgIdQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type GetAllAwardsByOrgIdQuery = { __typename?: 'query_root', awards: Array<{ __typename?: 'awards', id: any, name: string, cycle: Types.Enum_Award_Cycle_Type_Enum, description?: string | null, created_at: any, created_by: any, permissions?: string | null, limit?: number | null }> };

export type GetAwardDetailsByIdQueryVariables = Types.Exact<{
  awardId: Types.Scalars['uuid'];
}>;


export type GetAwardDetailsByIdQuery = { __typename?: 'query_root', awards: Array<{ __typename?: 'awards', id: any, name: string, cycle: Types.Enum_Award_Cycle_Type_Enum, description?: string | null, created_at: any, created_by: any, permissions?: string | null, limit?: number | null }> };

export type UpdateAwardsByIdMutationVariables = Types.Exact<{
  awardId: Types.Scalars['uuid'];
  data: Types.Awards_Set_Input;
}>;


export type UpdateAwardsByIdMutation = { __typename?: 'mutation_root', update_awards_by_pk?: { __typename?: 'awards', id: any } | null };

export type UpdateAwardsByOrgIdMutationVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
  data: Types.Awards_Set_Input;
}>;


export type UpdateAwardsByOrgIdMutation = { __typename?: 'mutation_root', update_awards?: { __typename?: 'awards_mutation_response', returning: Array<{ __typename?: 'awards', id: any }> } | null };

export type DeleteAwardsByIdMutationVariables = Types.Exact<{
  awardId: Types.Scalars['uuid'];
}>;


export type DeleteAwardsByIdMutation = { __typename?: 'mutation_root', delete_awards?: { __typename?: 'awards_mutation_response', affected_rows: number } | null, delete_awards_by_pk?: { __typename?: 'awards', id: any, name: string, cycle: Types.Enum_Award_Cycle_Type_Enum } | null };

export type CheckIsAwardActiveSubscriptionVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type CheckIsAwardActiveSubscription = { __typename?: 'subscription_root', organization_awards: Array<{ __typename?: 'organization_awards', id: any, is_active: boolean }> };

export type CheckIsManagerActiveSubscriptionVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type CheckIsManagerActiveSubscription = { __typename?: 'subscription_root', organization_awards: Array<{ __typename?: 'organization_awards', id: any, manager_permission: boolean }> };



export function useGetOrgAwardsQuery(options: Omit<Urql.UseQueryArgs<Operations.GetOrgAwardsQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.GetOrgAwardsQuery, Operations.GetOrgAwardsQueryVariables>({ query: Operations.GetOrgAwardsDocument, ...options });
};

export function useUpdateOrganizationAwardsByOrgIdMutation() {
  return Urql.useMutation<Operations.UpdateOrganizationAwardsByOrgIdMutation, Operations.UpdateOrganizationAwardsByOrgIdMutationVariables>(Operations.UpdateOrganizationAwardsByOrgIdDocument);
};

export function useInsertOrganizationAwardMutation() {
  return Urql.useMutation<Operations.InsertOrganizationAwardMutation, Operations.InsertOrganizationAwardMutationVariables>(Operations.InsertOrganizationAwardDocument);
};

export function useCreateNewAwardTypeMutation() {
  return Urql.useMutation<Operations.CreateNewAwardTypeMutation, Operations.CreateNewAwardTypeMutationVariables>(Operations.CreateNewAwardTypeDocument);
};

export function useGetAllAwardsByOrgIdQuery(options: Omit<Urql.UseQueryArgs<Operations.GetAllAwardsByOrgIdQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.GetAllAwardsByOrgIdQuery, Operations.GetAllAwardsByOrgIdQueryVariables>({ query: Operations.GetAllAwardsByOrgIdDocument, ...options });
};

export function useGetAwardDetailsByIdQuery(options: Omit<Urql.UseQueryArgs<Operations.GetAwardDetailsByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.GetAwardDetailsByIdQuery, Operations.GetAwardDetailsByIdQueryVariables>({ query: Operations.GetAwardDetailsByIdDocument, ...options });
};

export function useUpdateAwardsByIdMutation() {
  return Urql.useMutation<Operations.UpdateAwardsByIdMutation, Operations.UpdateAwardsByIdMutationVariables>(Operations.UpdateAwardsByIdDocument);
};

export function useUpdateAwardsByOrgIdMutation() {
  return Urql.useMutation<Operations.UpdateAwardsByOrgIdMutation, Operations.UpdateAwardsByOrgIdMutationVariables>(Operations.UpdateAwardsByOrgIdDocument);
};

export function useDeleteAwardsByIdMutation() {
  return Urql.useMutation<Operations.DeleteAwardsByIdMutation, Operations.DeleteAwardsByIdMutationVariables>(Operations.DeleteAwardsByIdDocument);
};

export function useCheckIsAwardActiveSubscription<TData = Operations.CheckIsAwardActiveSubscription>(options: Omit<Urql.UseSubscriptionArgs<Operations.CheckIsAwardActiveSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<Operations.CheckIsAwardActiveSubscription, TData>) {
  return Urql.useSubscription<Operations.CheckIsAwardActiveSubscription, TData, Operations.CheckIsAwardActiveSubscriptionVariables>({ query: Operations.CheckIsAwardActiveDocument, ...options }, handler);
};

export function useCheckIsManagerActiveSubscription<TData = Operations.CheckIsManagerActiveSubscription>(options: Omit<Urql.UseSubscriptionArgs<Operations.CheckIsManagerActiveSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<Operations.CheckIsManagerActiveSubscription, TData>) {
  return Urql.useSubscription<Operations.CheckIsManagerActiveSubscription, TData, Operations.CheckIsManagerActiveSubscriptionVariables>({ query: Operations.CheckIsManagerActiveDocument, ...options }, handler);
};
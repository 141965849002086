import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InsertStorageFileMutationVariables = Types.Exact<{
  object: Types.Storage_Files_Insert_Input;
}>;


export type InsertStorageFileMutation = { __typename?: 'mutation_root', insert_storage_files_one?: { __typename?: 'storage_files', id: any } | null };



export function useInsertStorageFileMutation() {
  return Urql.useMutation<Operations.InsertStorageFileMutation, Operations.InsertStorageFileMutationVariables>(Operations.InsertStorageFileDocument);
};
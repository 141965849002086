import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FrontendCreateAnnouncementMutationVariables = Types.Exact<{
  message: Types.Scalars['String'];
}>;


export type FrontendCreateAnnouncementMutation = { __typename?: 'mutation_root', createAnAnnouncement: { __typename?: 'createAnnouncementOutput', post_id: string } };

export type GetPostsByOrgIdQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type GetPostsByOrgIdQuery = { __typename?: 'query_root', posts: Array<{ __typename?: 'posts', id: any, message: string }> };

export type UpdatePostMessageMutationVariables = Types.Exact<{
  postId: Types.Scalars['uuid'];
  message: Types.Scalars['String'];
}>;


export type UpdatePostMessageMutation = { __typename?: 'mutation_root', update_posts_by_pk?: { __typename?: 'posts', id: any } | null };

export type DeleteAnnouncementMutationVariables = Types.Exact<{
  announcementId: Types.Scalars['String'];
}>;


export type DeleteAnnouncementMutation = { __typename?: 'mutation_root', editPost?: { __typename?: 'editPostOutput', ok?: boolean | null, message?: string | null, post_id?: string | null } | null };



export function useFrontendCreateAnnouncementMutation() {
  return Urql.useMutation<Operations.FrontendCreateAnnouncementMutation, Operations.FrontendCreateAnnouncementMutationVariables>(Operations.FrontendCreateAnnouncementDocument);
};

export function useGetPostsByOrgIdQuery(options: Omit<Urql.UseQueryArgs<Operations.GetPostsByOrgIdQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.GetPostsByOrgIdQuery, Operations.GetPostsByOrgIdQueryVariables>({ query: Operations.GetPostsByOrgIdDocument, ...options });
};

export function useUpdatePostMessageMutation() {
  return Urql.useMutation<Operations.UpdatePostMessageMutation, Operations.UpdatePostMessageMutationVariables>(Operations.UpdatePostMessageDocument);
};

export function useDeleteAnnouncementMutation() {
  return Urql.useMutation<Operations.DeleteAnnouncementMutation, Operations.DeleteAnnouncementMutationVariables>(Operations.DeleteAnnouncementDocument);
};
import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateHolidayMutationVariables = Types.Exact<{
  data: Array<Types.Organization_Holidays_Insert_Input> | Types.Organization_Holidays_Insert_Input;
}>;


export type CreateHolidayMutation = { __typename?: 'mutation_root', insert_organization_holidays?: { __typename?: 'organization_holidays_mutation_response', affected_rows: number } | null };

export type GetHolidaysQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid'];
}>;


export type GetHolidaysQuery = { __typename?: 'query_root', holidays: Array<{ __typename?: 'organization_holidays', id: any, name: string, description?: string | null, date: any }> };

export type UpdateHolidayMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  data: Types.Organization_Holidays_Set_Input;
}>;


export type UpdateHolidayMutation = { __typename?: 'mutation_root', holiday?: { __typename?: 'organization_holidays', id: any } | null };

export type DeleteHolidayMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteHolidayMutation = { __typename?: 'mutation_root', holiday?: { __typename?: 'organization_holidays', id: any } | null };



export function useCreateHolidayMutation() {
  return Urql.useMutation<Operations.CreateHolidayMutation, Operations.CreateHolidayMutationVariables>(Operations.CreateHolidayDocument);
};

export function useGetHolidaysQuery(options: Omit<Urql.UseQueryArgs<Operations.GetHolidaysQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.GetHolidaysQuery, Operations.GetHolidaysQueryVariables>({ query: Operations.GetHolidaysDocument, ...options });
};

export function useUpdateHolidayMutation() {
  return Urql.useMutation<Operations.UpdateHolidayMutation, Operations.UpdateHolidayMutationVariables>(Operations.UpdateHolidayDocument);
};

export function useDeleteHolidayMutation() {
  return Urql.useMutation<Operations.DeleteHolidayMutation, Operations.DeleteHolidayMutationVariables>(Operations.DeleteHolidayDocument);
};